import React, { useEffect, useState } from 'react'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const dataCardText = [
     {
          title: '<span>Take Back</span> Your Data',
          desc: `You're spending money on marketing, and data is the most important output of that spend. At ListenLayer, we help serious marketers get control over their data strategies to drive better analysis and optimization.`,
     },
     {
          title: 'Collaborate',
          desc:
               'We promote a collaborative, team approach to defining and deploying a successful data strategy, rather than outsourcing this critical component to a freelancer, agency, or your media manager and hoping for the best.',
     },
     {
          title: `Don't Do This Alone`,
          desc: 'We offer implementation and managed services to help you succeed, or rely on our automated onboarding and world-class support.',
     },
]

const pageName = 'Centralized Marketing Data'
const breadcrumbItems = [
     {
          name: 'Platform',
          url: `${'platform'}`,
     },
     {
          name: `${pageName}`,
     },
]

const CentralizedMarketingData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="centralized-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
               {/* section 1 */}
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>Centralize Your Marketing Data Strategy</h1>
                              <p>
                                   Define and generate conversions and other KPIs from a single source of truth. Enrich and send the data where you
                                   need for optimization.
                              </p>
                         </div>
                    </div>
               </section>

               {/* section 2 */}
               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step">
                              <div className="row">
                                   <div className="col-md-12 col-lg-4 mb-4">
                                        <div className="card-step">
                                             <div className="card-body">
                                                  <h3>Step 1:</h3>
                                                  <p className="card-text">Plan Your Strategy</p>
                                                  <p className="card-desc">
                                                       Use ListenLayer to build a single, centralized strategy for collecting data from your website &
                                                       marketing activities.
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-12 col-lg-4 mb-4">
                                        <div className="card-step">
                                             <div className="card-body">
                                                  <h3>Step 2:</h3>
                                                  <p className="card-text">Define Your Goals</p>
                                                  <p className="card-desc">
                                                       Define various types of goals, such as Web Conversions, Funnels, Cohorts, and Target Accounts.
                                                       Then measure and report on activity from a single source.
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-md-12 col-lg-4 mb-4">
                                        <div className="card-step">
                                             <div className="card-body">
                                                  <h3>Step 3:</h3>
                                                  <p className="card-text">Learn & Distribute</p>
                                                  <p className="card-desc">
                                                       Send data where you need it using client-side or server-side tracking. Your centralized
                                                       definitions of conversions and KPIs will flow to all channels and tools.
                                                  </p>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="text-desc">
                              <p>
                                   Most marketing teams struggle with disparate data structures that live across multiple platforms. Inconsistencies
                                   in KPIs, conversion tracking, and attribution models lead to incomplete data, at best, and totally inaccurate data
                                   at worst.
                              </p>
                              <p>
                                   If you're like most most marketers, you don't have a data strategy! And that's not your fault... with each
                                   marketing platform using a different approach, there is no single method of implementation - and who has time to
                                   become an expert in every platform?
                              </p>
                              <p>
                                   On average, a single marketing team utilizes 6 platforms to collect and track data. Common locations include Meta
                                   (Facebook Ads), Google Ads, LinkedIn, Pinterest, Google Analytics 4, Microsoft Ads, and more. It's important for
                                   each of these desinations to share a single data strategy that aligns to your organization's goals. But that's
                                   impossible if multiple people or teams configure varying versions of "conversion tracking" independantly in each of
                                   these platforms.
                              </p>
                              <p className="text-bold">
                                   ListenLayer allows you to centralize a single data strategy, and distribute the truth where you need it. And the
                                   best part... you don't need to be a programmer or analytics expert.
                              </p>
                         </div>
                    </div>
               </section>
               {/* section 3 */}
               <SectionBoxReady />

               <section className="centralized-benefits">
                    <div className="container">
                         <div className="centralized-benefits-data">
                              <div className="title">
                                   <h3>The Benefits of Centralized Data</h3>
                                   <p>ListenLayer generates insights and helps marketers in the following ways...</p>
                              </div>
                              <div className="list-data">
                                   <ul>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>Operate from a single definition of success and unify KPIs</span>
                                        </li>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>Reduce confusion about what data to rely on and get your team on the same page</span>
                                        </li>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>Reduce management time and avoid manual errors from disparate configurations</span>
                                        </li>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>Distribute your data consistently to all advertising platforms and analytics tools</span>
                                        </li>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>Maintain a centralized analytics source that is accurate and reliable</span>
                                        </li>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>
                                                  Connect external data sources, such as Salesforce.com, to build an understanding of attribution and
                                                  influence
                                             </span>
                                        </li>
                                        <li>
                                             <img src="/images/icon-tick.svg" alt="Icon Tick" />
                                             <span>Generate automated insights from your data</span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               </section>
               {/* section 4 */}
               <SectionBoxCardText data={dataCardText} />
               <SectionBoxReady />
          </Layout>
     )
}

export default CentralizedMarketingData
